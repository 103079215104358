import { memo, FC } from 'react';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { Modal } from 'uikit/Modal';
import { Button } from 'uikit/Buttons/Button';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Theme } from 'uikit/common/types';
import { AttentionAddOrderProps } from './types';
import classes from './AttentionAddOrder.module.scss';

export const AttentionAddOrder: FC<AttentionAddOrderProps> = memo(({
  show, onClose, onBack, onReplace,
}) => {
  const theme = useAppSelector(themeSelector);
  return (
    <Modal
      show={show}
      onClose={onClose}
      theme={theme}
      dialogClassName={classes.dialog}
      bodyClassName={classes.body}
    >
      <Box direction="column" className={classes[theme]}>
        <Box className={classes.title} justifyContent="center">Attention</Box>
        <Box className={classes.content}>
          This offer is not compatible with offers in your order, do you want to add it and replace them?
        </Box>
        <Box className={classes.btns} justifyContent="space-between">
          <Button
            onClick={onBack}
            className={cn(classes.btn, classes.backBtn)}
            theme={theme}
            variant={theme === Theme.dark ? 'black' : 'white'}
          >
            Back
          </Button>
          <Button
            onClick={onReplace}
            className={cn(classes.btn, classes.replaceBtn)}
            theme={theme}
          >
            Replace
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});