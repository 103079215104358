import { useMemo } from 'react';
import { FormOffers } from 'lib/features/createOrder/types';
import { getExtendedFormOffers } from 'lib/features/createOrder/helpers';
import { useOffersAndTeeOffers } from './useOffersAndTeeOffers';

export const useExtendedFormOffers = (formOffers: FormOffers) => {
  const { loading, offers, teeOffers } = useOffersAndTeeOffers(formOffers);
  const extendedFormOffers = useMemo(
    () => getExtendedFormOffers({ formOffers, offers, teeOffers }),
    [formOffers, offers, teeOffers],
  );
  return {
    formOffers: extendedFormOffers,
    loading,
  };
};