import { useCallback } from 'react';
import { TeeOffer, Offer } from 'generated/types';
import { useLazyGetOffersQuery } from 'lib/features/offers';
import { useLazyGetTeeOffersQuery } from 'lib/features/teeOffers';
import { FieldsBuildOrderForm } from '../types';
import { getBaseOffers } from '../helpers';

export const useBaseOffers = () => {
  const [getOffers, offersResponse] = useLazyGetOffersQuery();
  const [getTeeOffers, teeOffersResponse] = useLazyGetTeeOffersQuery();
  const getBaseOffersData = useCallback(async (offer: Offer | TeeOffer | null | undefined, field: FieldsBuildOrderForm) => {
    if (!offer) return { ids: [], data: [] };
    const ids = offer ? getBaseOffers(offer) : [];
    const offersResponse = ids?.length
      ? await (field === FieldsBuildOrderForm.tee ? getTeeOffers : getOffers)({
        filter: { ids },
        pagination: { first: null },
      })
      : null;
    if (offersResponse?.error) {
      throw offersResponse.error;
    }
    const data = offersResponse
      ? (offersResponse?.data?.result?.page?.edges || []).map((edge) => edge?.node as Offer | TeeOffer)
      : [];
    return {
      ids,
      data,
    };
  }, [getTeeOffers, getOffers]);

  return {
    loading: teeOffersResponse?.isLoading || offersResponse?.isLoading,
    getOffers: getBaseOffersData,
  };
};