import { createSelector } from '@reduxjs/toolkit';

import { formSelector } from '.';
import {
  Steps, Form, FieldsBuildOrderForm,
} from './types';
import { getFormBuildOrderIds, getFormBuildOrdersCount } from './helpers';

export const buildOrderFormSelector = createSelector(
  formSelector,
  (form: Form) => form?.[Steps.BUILD_ORDER],
);

export const formOffersSelector = createSelector(
  formSelector,
  (form: Form) => {
    const buildOrderForm = form?.[Steps.BUILD_ORDER] || {};
    return {
      [FieldsBuildOrderForm.solution]: buildOrderForm[FieldsBuildOrderForm.solution],
      [FieldsBuildOrderForm.tee]: buildOrderForm[FieldsBuildOrderForm.tee],
      [FieldsBuildOrderForm.storage]: buildOrderForm[FieldsBuildOrderForm.storage],
      [FieldsBuildOrderForm.data]: buildOrderForm[FieldsBuildOrderForm.data],
    };
  },
);

export const createPassphraseFormSelector = createSelector(
  formSelector,
  (form: Form) => form?.[Steps.CREATE_PASSPHRASE],
);

export const formBuildOrderIdsSelector = createSelector(
  buildOrderFormSelector,
  getFormBuildOrderIds,
);

export const formBuildOrdersCountSelector = createSelector(
  buildOrderFormSelector,
  getFormBuildOrdersCount,
);
