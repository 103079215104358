import { useCallback, useMemo } from 'react';
import qs from 'qs';
import { useRouter } from 'next/router';
import { Slots } from 'lib/features/createOrder/types';
import { OfferTabs } from 'components/OfferModal/Offer/Left/types';

export interface UseOfferModalOffer {
  offerId?: string;
  slots?: Slots | null;
}

interface Query { [key: string]: string | string[] | undefined; }

export interface PrepareQueryResult extends Query {
  offer?: string;
  tab?: OfferTabs;
}

export const useOfferModal = () => {
  const { query, push } = useRouter();
  const { offer: offerFromQuery, tab } = query;
  const offer: UseOfferModalOffer | null = useMemo(() => {
    const offerFromQueryParsed = typeof offerFromQuery === 'string' ? qs.parse(offerFromQuery) as UseOfferModalOffer : null;
    if (!offerFromQueryParsed) return null;
    const { offerId, slots } = offerFromQueryParsed || {};
    const { slot, options } = slots || {};
    if (!offerId) return null;
    return {
      offerId,
      // prepare slots types
      slots: slots
        ? {
          slot: slot?.id ? { id: slot?.id, count: +(slot?.count || 0) } : null,
          options: options?.length ? options.map(({ id, count }) => ({ id, count: +(count || 0) })) : null,
        }
        : null,
    };
  }, [offerFromQuery]);

  const onClose = useCallback(() => {
    const newQuery = { ...query };
    delete newQuery.offer;
    delete newQuery.tab;
    push({ query: newQuery }, undefined, { shallow: true });
  }, [push, query]);

  const prepareQuery = useCallback((offerId?: string, slots?: Slots | null, tab?: OfferTabs | null): PrepareQueryResult => {
    if (!offerId) return {};
    const obj: UseOfferModalOffer = {
      offerId,
      ...(slots ? { slots } : {}),
    };
    return {
      ...query,
      offer: qs.stringify(obj),
      ...(tab ? { tab } : {}),
    };
  }, [query]);

  const openModal = useCallback((offerId?: string, slots?: Slots | null, tab?: OfferTabs | null) => {
    push({ query: prepareQuery(offerId, slots, tab) }, undefined, { shallow: true });
  }, [push, prepareQuery]);

  const onChangeTab = useCallback((tab?: OfferTabs) => {
    push({ query: { ...query, tab } }, undefined, { shallow: true });
  }, [query, push]);

  return {
    onClose, offer, prepareQuery, openModal, tab: tab as OfferTabs | undefined, onChangeTab,
  };
};
