import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAppDispatch } from 'lib/hooks';
import { Slots } from 'lib/features/createOrder/types';
import { trackEvent } from 'lib/features/events/thunks';
import { OfferTabs } from 'components/OfferModal/Offer/Left/types';
import { getPreparedSlots } from 'lib/features/createOrder/helpers';
import { Offer, TeeOffersAndSLots } from 'generated/types';
import { useOfferModal } from './useOfferModal';

export const useOnClickRow = () => {
  const { prepareQuery } = useOfferModal();
  const { push } = useRouter();
  const dispatch = useAppDispatch();
  const openModal = useCallback((offerId: string, slots: Slots, tab: OfferTabs) => {
    push(
      {
        query: prepareQuery(offerId, slots, tab),
      },
      undefined,
      { shallow: true },
    );
    dispatch(trackEvent({
      eventType: 'open_offer_description',
      property: { offerId },
    }));
  }, [push, dispatch, prepareQuery]);

  const onClickRow = useCallback((_: string, rowData: TeeOffersAndSLots | Offer) => {
    const offerId = (rowData as TeeOffersAndSLots)?.teeOffer?.id || (rowData as Offer)?.id;
    openModal(offerId, getPreparedSlots(rowData), (rowData as TeeOffersAndSLots)?.teeOffer ? OfferTabs.pricing : OfferTabs.about);
  }, [openModal]);

  return { onClickRow, openModal };
};
